import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { facadeFactory, provideDefaultConfig, provideDefaultConfigFactory } from '@spartacus/core';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const GOOGLE_MAPS_DEVELOPMENT_KEY_CONFIG = 'cx-development';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultStoreFinderLayoutConfig = {
  layoutSlots: {
    StoreFinderPageTemplate: {
      slots: ['MiddleContent', 'SideContent']
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const STORE_FINDER_FEATURE = 'storeFinder';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Store the Point of Service a user wants to collect a product from before it is added to the cart.
 */
class StoreFinderFacade {
  static {
    this.ɵfac = function StoreFinderFacade_Factory(t) {
      return new (t || StoreFinderFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StoreFinderFacade,
      factory: () => (() => facadeFactory({
        facade: StoreFinderFacade,
        feature: STORE_FINDER_FEATURE,
        methods: ['getStoresLoading', 'getStoresLoaded', 'getFindStoresEntities', 'getViewAllStoresLoading', 'getViewAllStoresEntities', 'findStoresAction', 'viewAllStores', 'viewStoreById', 'callFindStoresAction', 'getStoreLatitude', 'getStoreLongitude', 'getDirections', 'getFindStoreEntityById'],
        async: true
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StoreFinderFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: StoreFinderFacade,
        feature: STORE_FINDER_FEATURE,
        methods: ['getStoresLoading', 'getStoresLoaded', 'getFindStoresEntities', 'getViewAllStoresLoading', 'getViewAllStoresEntities', 'findStoresAction', 'viewAllStores', 'viewStoreById', 'callFindStoresAction', 'getStoreLatitude', 'getStoreLongitude', 'getDirections', 'getFindStoreEntityById'],
        async: true
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
var StoreFinderOutlets;
(function (StoreFinderOutlets) {
  StoreFinderOutlets["PREFERRED_STORE"] = "cx-pick-up-in-store-make-my-store";
})(StoreFinderOutlets || (StoreFinderOutlets = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
// TODO: Inline this factory when we start releasing Ivy compiled libraries
function defaultStoreFinderComponentsConfig() {
  const config = {
    featureModules: {
      [STORE_FINDER_FEATURE]: {
        cmsComponents: ['StoreFinderComponent']
      }
    }
  };
  return config;
}
class StoreFinderRootModule {
  static {
    this.ɵfac = function StoreFinderRootModule_Factory(t) {
      return new (t || StoreFinderRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: StoreFinderRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig(defaultStoreFinderLayoutConfig), provideDefaultConfigFactory(defaultStoreFinderComponentsConfig)]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StoreFinderRootModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      providers: [provideDefaultConfig(defaultStoreFinderLayoutConfig), provideDefaultConfigFactory(defaultStoreFinderComponentsConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { GOOGLE_MAPS_DEVELOPMENT_KEY_CONFIG, STORE_FINDER_FEATURE, StoreFinderFacade, StoreFinderOutlets, StoreFinderRootModule, defaultStoreFinderComponentsConfig, defaultStoreFinderLayoutConfig };
